<template>
  <div id="table-helper" class="pa-5">
    <TableHelper
      ref="table"
      :enable-select="true"
      :fields="fields"
      :items="items"
      :actions="actions"
      :meta="meta"
      :enable-filter="true"
      :filterable="filterable"
      loading-key="table-helper-demo"
      param-prefix="demo"
      @reload-data="loadData"
      @edit="showDetails"
      @editor="showDetails"
      @delete="deleteProject"
    />
  </div>
</template>

<script setup>
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import { getCurrentInstance, onMounted, ref } from "vue";
import Projects from "@/components/Settings/Projects/project";
import { useRouter } from "vue-router/composables";
import { LOAD_PROJECTS } from "@/core/services/store/switchArea.module";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { Error } from "@/core/plugins/swal";

const store = getCurrentInstance().proxy.$store;
const router = useRouter();
const swal = getCurrentInstance().proxy.$swal;
const i18n = getCurrentInstance().proxy.$i18n;
const toast = getCurrentInstance().proxy.$toast;
const table = ref(null);
const fields = ref([
  {
    key: "id",
    label: "ID",
    sortable: true,
    thStyle: { width: "350px" }
  },
  {
    key: "name",
    label: "Projekt",
    sortable: true
    // iconConfig: {
    //   true: "fal fa-lock",
    //   false: "fal fa-lock-open"
    // }
  }
]);

const items = ref([]);
const meta = ref({});
const filterable = ref({});

const actions = ref([
  {
    key: "editor",
    icon: "fal fa-paint-brush",
    emit: "editor",
    tooltip: "Test"
  },
  {
    key: "edit",
    icon: "fal fa-pen",
    emit: "edit"
  },
  {
    key: "delete",
    icon: "fal fa-trash",
    emit: "delete"
  },
  {
    key: "options",
    icon: "fal fa-ellipsis-v",
    tooltip: "Mehr",
    children: [
      {
        key: "example1",
        label: "Example 1",
        emit: "example1"
      },
      {
        key: "example2",
        label: "Example 2",
        emit: "example2"
      }
    ]
  }
]);

onMounted(() => loadData());

function loadData(params) {
  addEventToLoadingQueue({ key: "table-helper-demo" });

  Projects.getAll(params)
    .then(response => {
      items.value = response.data;
      meta.value = response.meta;
      filterable.value = response.filterable;
    })
    .catch(error => {
      Error(error);
    })
    .finally(() => {
      removeEventFromLoadingQueue({ key: "table-helper-demo" });
    });
}

function showDetails(data) {
  router.push({
    name: "projectSettingsProjectsDetails",
    params: { projectId: data.item.id }
  });
}

function deleteProject(data) {
  let project = data.item;

  swal
    .fire({
      title: i18n.t("projects.removeProject", { name: project.name }),
      icon: "warning",
      showConfirmButton: true,
      confirmButtonText: i18n.t("general.remove"),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: i18n.t("general.cancel")
    })
    .then(swalResponse => {
      if (swalResponse.isConfirmed) {
        table.value.isBusy = true;
        Projects.delete(project.id)
          .then(() => {
            showDeletedToast(project.name);
            loadData();
            store.dispatch(LOAD_PROJECTS);
          })
          .catch(error => {
            swal.fire({
              title: i18n.t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            table.value.isBusy = false;
          });
      }
    });
}

function showDeletedToast(name) {
  toast.fire({
    icon: "success",
    title: i18n.t("projects.deleted", { name: name })
  });
}
</script>
